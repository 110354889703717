import { memo } from 'react';
import classnames from 'classnames';

export type Props = {
  data: {
    sectionTitle: string;
    reviews: {
      title: string;
      date: string;
      stars: number;
      review: string;
      name: string;
    }[];
    average: number;
    total: number;
    stars: number;
  };
};

function CustomersReviews({ data: { sectionTitle, reviews, average, total, stars } }: Props) {
  return (
    <section className="CustomersReviews vertical-spacing">
      <div className="horizontal-spacing">
        <h2 className="text-xl font-bold sm:text-2xl text-brandblue">{sectionTitle}</h2>

        <div className="flex items-center mt-4">
          <p className="text-3xl font-medium">
            {average}
            <span className="sr-only"> Average review score </span>
          </p>

          <div className="ml-4">
            <div className="flex -ml-1">
              {[1, 2, 3, 4, 5].map((number, index) => (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={classnames('w-5 h-5', {
                    'text-brandyellow': number <= stars,
                    'text-gray-200': number > stars
                  })}
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  key={index}
                >
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
              ))}
            </div>

            <p className="mt-0.5 text-xs gray-color">Based on {total} reviews</p>
          </div>
        </div>

        <div className="grid grid-cols-1 mt-8 lg:grid-cols-2 grid-gap">
          {reviews?.map(({ title, date, stars, review, name }, index) => (
            <blockquote key={index}>
              <header className="sm:items-center sm:flex">
                <div className="flex -ml-1">
                  {[1, 2, 3, 4, 5].map((number, index) => (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className={classnames('w-5 h-5', {
                        'text-brandyellow': number <= stars,
                        'text-gray-200': number > stars
                      })}
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      key={index}
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                    </svg>
                  ))}
                </div>

                <p className="mt-2 font-bold sm:ml-4 sm:mt-0">{title}</p>
              </header>

              <p className="mt-2 text-gray-700">{review}</p>

              <footer className="mt-4">
                <p className="text-xs gray-color">
                  {name} - {date}
                </p>
              </footer>
            </blockquote>
          ))}
        </div>
      </div>
    </section>
  );
}

export default memo(CustomersReviews);
