import { memo } from 'react';

import Image from '@/components/Image/Image';

export type Props = {
  data: {
    title: string;
    description: string;
    image: {
      url: string;
    };
  };
};

function Description({ data: { title, description, image } }: Props) {
  return (
    <section className="Description vertical-spacing">
      <div className="horizontal-spacing">
        <div className="grid grid-cols-1 lg:grid-cols-2 grid-gap lg:h-[80vh] lg:items-center">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="text-2xl font-bold text-transparent sm:text-5xl text-brandblue">{title}</h1>

            <p className="max-w-xl mx-auto mt-6 text-lg lg:text-xl">{description}</p>
          </div>
          <div className="relative h-64 sm:h-96 lg:h-[80vh]">
            {Boolean(image?.url) && (
              <Image
                className="absolute inset-0 image-cover w-full h-full rounded-xl"
                src={image.url}
                loadingType="eager"
                alt="Little Ryna main features"
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default memo(Description);
