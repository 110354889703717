import { memo, useEffect, useRef } from 'react';
import Swiper, { Pagination } from 'swiper';

import Image from '@/components/Image/Image';

export type Props = {
  data: {
    label: string;
    description: string;
    eyesData: {
      label: string;
      title: string;
      image: {
        url: string;
      };
    }[];
  };
};

function EyesShowcase({ data: { label, description, eyesData } }: Props) {
  const swiperRef = useRef<HTMLDivElement>(null);
  const paginationRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current) {
      new Swiper(swiperRef.current, {
        modules: [Pagination],
        loop: false,
        spaceBetween: 32,
        slidesPerView: 1,
        pagination: {
          clickable: true,
          el: paginationRef.current
        },
        breakpoints: {
          640: {
            slidesPerView: 2
          },
          1024: {
            slidesPerView: 3
          }
        }
      });
    }
  }, []);

  return (
    <section className="EyesShowcase vertical-spacing">
      <div className="horizontal-spacing">
        <div className="text-center">
          {Boolean(label) && <h2 className="text-lg font-bold mb-2 text-brandblue">{label}</h2>}
          {Boolean(description) && <p className="text-2xl font-medium mb-12">{description}</p>}
        </div>

        <div className="mt-8 swiper" ref={swiperRef}>
          <ul className="swiper-wrapper">
            {eyesData.map(({ label, title, image }, index) => {
              return (
                <li className="swiper-slide" key={index}>
                  <div>
                    <div>
                      <p className="text-xs text-brandblue-500">{label}</p>

                      <h5 className="font-bold">{title}</h5>
                    </div>

                    <div className="aspect-w-1 aspect-h-1 cursor-grab">
                      <Image alt={title} className="image-cover" src={image.url} />
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="mt-12 swiper-pagination" ref={paginationRef}></div>
      </div>
    </section>
  );
}

export default memo(EyesShowcase);
