import { memo } from 'react';

import Image from '@/components/Image/Image';

export type Props = {
  data: {
    images: {
      url: string;
    }[];
  };
};

function GridImages({ data: { images } }: Props) {
  return (
    <section className="GridImages vertical-spacing">
      <div className="horizontal-spacing">
        <div className="grid grid-cols-2 mt-8 lg:grid-cols-3 grid-gap">
          {images.map(({ url }, index) => (
            <div className="block overflow-hidden group" key={index}>
              <div className="shadow-sm border-2 border-black-400 rounded-3xl overflow-hidden">
                <Image
                  className="group-hover:scale-105 transition-transform duration-500 image-cover w-full h-[250px] sm:h-[350px]"
                  src={url}
                  alt="Product"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default memo(GridImages);
