import { memo, useEffect, useRef } from 'react';
import Swiper, { Pagination } from 'swiper';

import Image from '@/components/Image/Image';

import AmazonSVG from '@/components/svgs/amazoncom.svg';

export type Props = {
  data: {
    title: string;
    description: string;
    images: {
      url: string;
    }[];
    buttonText: string;
    buttonLink: string;
  };
};

function Hero({ data: { title, description, buttonLink, images } }: Props) {
  const swiperRef = useRef<HTMLDivElement>(null);
  const paginationRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current) {
      new Swiper(swiperRef.current, {
        modules: [Pagination],
        loop: false,
        spaceBetween: 32,
        slidesPerView: 1,
        pagination: {
          clickable: true,
          el: paginationRef.current
        },
        breakpoints: {
          640: {
            slidesPerView: 1
          },
          1024: {
            slidesPerView: 1
          }
        }
      });
    }
  }, []);

  return (
    <section className="Hero horizontal-spacing mb-24 mt-12 md:mt-9 lg:mt-0">
      <div className="grid grid-cols-1 lg:grid-cols-2 grid-gap lg:h-[80vh] lg:items-center">
        <div className="max-w-lg mx-auto lg:text-left lg:py-24">
          {Boolean(title) && <h2 className="text-3xl font-bold sm:text-4xl text-brandblue">{title}</h2>}

          {Boolean(description) && <p className="mt-4 gray-color">{description}</p>}

          {Boolean(buttonLink) && (
            <a
              className="block no-underline mt-8 mb-2 border-0 bg-black w-40 py-0.5 px-6 rounded-lg transition-transform duration-300 hover:scale-105"
              href={buttonLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="text-white normal-case font-body mb-[1px]">Buy from</span>
              <AmazonSVG className="w-[80%] mb-1" />
            </a>
          )}
        </div>

        <div className="relative h-64 sm:h-96 lg:h-[80vh]">
          <div className="h-full swiper" ref={swiperRef}>
            <ul className="swiper-wrapper">
              {images.map(({ url }, index) => {
                return (
                  <li className="swiper-slide" key={index}>
                    {Boolean(url) && (
                      <Image
                        className="absolute inset-0 image-cover w-full h-full rounded-xl"
                        src={url}
                        loadingType="eager"
                        alt="Little Ryna main features"
                      />
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="mt-6 lg:mt-0 swiper-pagination" ref={paginationRef}></div>
        </div>
        {/* <div className="relative h-64 sm:h-96 lg:h-[80vh]">
          {Boolean(image?.url) && (
            <Image
              className="absolute inset-0 image-cover w-full h-full rounded-xl"
              src={image.url}
              loadingType="eager"
              alt="Little Ryna main features"
            />
          )}
        </div> */}
      </div>
    </section>
  );
}

export default memo(Hero);
