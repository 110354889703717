// Global
export const resizeDebounceTime = 10; // in ms

// Head
export const siteName = 'Little Ryna';
// TODO: // add SEO stuff
export const siteDescription = 'Little Ryna';
export const siteSlogan = 'Happy childcare';
export const siteKeywords = ['little ryna', 'baby sippy cup', 'straw', 'eyes emotion sippy cup'];

// GraphQL
export const graphCmsUrl = 'https://api-us-east-1.graphcms.com/v2/cl0313gzs0bmi01xoead7frja/master';
