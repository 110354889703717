import { memo, useRef } from 'react';
import { ApolloClient, gql, InMemoryCache } from '@apollo/client';
import classnames from 'classnames';

import styles from './index.module.scss';

import CustomersReviews, { Props as CustomersReviewsProps } from '@/components/CustomersReviews/CustomersReviews';
import Description, { Props as DescriptionProps } from '@/components/Description/Description';
import EyesShowcase, { Props as EyesShowcaseProps } from '@/components/EyesShowcase/EyesShowcase';
import FeaturesGrid, { Props as FeaturesGridProps } from '@/components/FeaturesGrid/FeaturesGrid';
import GridImages, { Props as GridImagesProps } from '@/components/GridImages/GridImages';
import Head from '@/components/Head/Head';
import Hero, { Props as HeroProps } from '@/components/Hero/Hero';

import { graphCmsUrl } from '@/data/settings';

export type Props = {
  className: string;
  data: {
    hero: HeroProps['data'];
    eyesShowcase: EyesShowcaseProps['data'];
    featuresShowcase: FeaturesGridProps['data'];
    description: DescriptionProps['data'];
    imageGrid: GridImagesProps['data'];
    customerReviews: CustomersReviewsProps['data'];
  };
};

export async function getStaticProps() {
  const client = new ApolloClient({
    uri: graphCmsUrl,
    cache: new InMemoryCache()
  });

  const { hero, eyesShowcase, featuresShowcase, imageGrid, description, customerReviews } = await client
    .query({
      query: gql`
        query GetData {
          hero(where: { id: "cl03ngb65fblj0aodpb42sz7k" }) {
            title
            description
            images {
              url
            }
            buttonText
            buttonLink
          }
          eyesShowcase(where: { id: "cl03oaigbfsbd0bn231tt1zxy" }) {
            label
            description
            eyesData {
              label
              title
              image {
                url
              }
            }
          }
          featuresShowcase(where: { id: "cl03p30qjg1rc0cn8z68c3vxp" }) {
            mainTitle
            featuresData {
              label
              title
              description
              image {
                url
              }
            }
          }
          imageGrid(where: { id: "cl03pe7afg3yu0goh8y5d1gc8" }) {
            images {
              url
            }
          }
          customerReviews(where: { id: "cl04xj5xkxeri0gohd9uru5i6" }) {
            sectionTitle
            average
            total
            stars
            reviews {
              title
              date
              stars
              review
              name
            }
          }
          description(where: { id: "cl03pn7gxg9om0cn8dr9h9qhj" }) {
            title
            description
            image {
              url
            }
          }
        }
      `
    })
    .then(({ data }) => data);

  console.log(hero);

  return {
    props: {
      data: {
        hero,
        eyesShowcase,
        featuresShowcase,
        description,
        imageGrid,
        customerReviews
      }
    }
  };
}

function Home({ className, data }: Props) {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <main className={classnames(styles.Home, className)} ref={containerRef}>
      <Head />
      <Hero data={data.hero} />
      <EyesShowcase data={data.eyesShowcase} />
      <Description data={data.description} />
      <FeaturesGrid data={data.featuresShowcase} />
      <GridImages data={data.imageGrid} />
      <CustomersReviews data={data.customerReviews} />
    </main>
  );
}

export default memo(Home);
