import { memo } from 'react';
import classnames from 'classnames';

import Image from '@/components/Image/Image';

export type Props = {
  data: {
    mainTitle: string;
    featuresData: {
      label: string;
      title: string;
      description: string;
      image: {
        url: string;
      };
    }[];
  };
};

function FeaturesGrid({ data: { mainTitle, featuresData } }: Props) {
  return (
    <section className="FeaturesGrid vertical-spacing">
      <div className="horizontal-spacing sm:px-4">
        <div>
          <span className="inline-block w-12 h-1 bg-brandorange"></span>

          <h2 className="mt-1 text-2xl font-bold tracking-wide uppercase lg:text-3xl text-brandblue">{mainTitle}</h2>
        </div>

        <div className="grid grid-cols-1 mt-8 md:grid-cols-2 lg:grid-cols-3 grid-gap">
          {featuresData.map(({ label, title, description, image }, index) => (
            <div key={index}>
              <div
                className={classnames('flex justify-center', {
                  'opacity-0': !Boolean(label)
                })}
              >
                <strong className="relative h-6 px-4 text-xs leading-6 text-white uppercase bg-black">{label}</strong>
              </div>

              <Image alt={title} src={image.url} className="image-cover w-full -mt-3 h-96 cursor-pointer" />

              <h5 className="mt-4 text-lg text-black/90">{title}</h5>

              <div className="flex items-center justify-between mt-2">
                <p className="text-lg">{description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default memo(FeaturesGrid);
